<template>
    <div>
        <Header :type='hType'/>
        <VendorDataForm :editType="editType" />
    </div>
</template>
<script>
import VendorDataForm from '@/components/VendorDataForm';
import Header from '@/components/Header';
export default {
    data(){
        return{
            editType:'adminEdit',
            hType: 'admin_manage',
        }
    },
    components:{
        VendorDataForm,Header
    },
}
</script>